import React from 'react';
import logo from './images/logo.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-wrapper">
                <FooterHeader />
                <FooterBody />
            </div>
            <FooterFootnote />
        </footer>
    );
}

const FooterHeader = () => {
    return (
        <div className="footer-header">
            <p>
                Bárhol is lakjon, mi készen állunk a vezeték nélküli technológia teljes kiaknázására, így lehetővé tesszük minden leendő és meglévő ügyfelünk részére, hogy az internet hozzáférésén kersztül a TV szolgáltatásokat, biztonsági kamerák elérését, és akár okos otthonának teljes vezérlését is egy kézből megkapja.
            </p>
            <a href="https://www.netsurfclub.hu/" target="_blank" rel="noreferrer">
                <img src={logo} alt="Netsurf logó" />
            </a>
            <a href="https://www.netsurfclub.hu/" target="_blank" rel="noreferrer">
                www.netsurfclub.hu
            </a>
        </div>
    );
}

const FooterBody = () => {
    return (
        <div className="footer-body">
            <div className="footer-social">
                <a className="footer-social-item" href="https://www.facebook.com/netsurfkft/" target="_blank" rel="noreferrer">
                    <i className="fab fa-facebook-f"></i>
                </a>
            </div>
        </div>
    );
}

const FooterFootnote = () => {
    return (
        <div className="footer-footnote">
            Netsurf Távközlési Szolgáltató © - 
            <a href="https://lionwebdesign.hu/" target="_blank" rel="noreferrer">
                <span> lion</span>webdesign
            </a>
        </div>
    );
}

export default Footer;