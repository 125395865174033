import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/style.css';


ReactDOM.render(
    <div className="wrapper">
        <App />
    </div>,
    document.getElementById('root')
);