import React from 'react';
import logo from './images/logo.png';

const Header = () => {
    return (
        <header className="header">
            <div className="header-wrapper">
                <Logo />
                <WelcomeMessage />
            </div>
        </header>
    );
}

const Logo = () => {
    return (
        <a href="https://www.netsurfclub.hu/" target="_blank" rel="noreferrer">
            <img src={logo} alt="logó" />
        </a>
    );
}

const WelcomeMessage = () => {
    return (
        <div className="welcome-message">
            <h1>
                Válaszd a hazait!
                <span>...bárhol, bárkinek, bármikor...</span>
            </h1>
            <h3>Villámgyors internet - korlátok és vezeték nélkül</h3>
            <h3>Tv az ország bármely pontján</h3>
            <h3>Biztonsági rendszerek</h3>
            <h3>Okos otthon vezérlés</h3>
            <p>
                Küldetésünk a városi színvonalú szolgáltatások eljuttatása bárhova, ahol ember lakik.
            </p>
        </div>
    );
}

export default Header;