import React from 'react';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import Info from './Info';

const App = () => {
    return (
        <div className="application">
            <Header />
            <Main />
            <Info />
            <Footer />
        </div>
    );
}

export default App;