import React from 'react';
import sofa from './images/sofa.png';

const Info = () => {
    return (
        <section className="info">
            <div className="info-wrapper">
                <img src={sofa} alt="netsurf kanapé" />
            </div>
            <div className="info-placeholder"></div>
        </section>
    );
}

export default Info;